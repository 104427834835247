import {ConfigExample} from "./index";

const Atreus: ConfigExample = {
    label: "Empty YAML configuration",
    author: "ceoloide",
    value: `meta:
  engine: 4.1.0
units:
points:
  zones:
    matrix:
outlines:
pcbs:
cases:
`
};

export default Atreus;